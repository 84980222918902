//import tags
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faGraduationCap } from "@fortawesome/free-solid-svg-icons";

// import resources
import Student from '../../assets/image/student.png'
import Student2 from '../../assets/image/student2.png'
import Student3 from '../../assets/image/student3.png'
import Student4 from '../../assets/image/student4.png'
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";

function Blog() {
  return (
    <div className="news">
      <Container>
        <Row>
          <Col xxl={12}>

            <div className="newsSliderBox">
                <div className="sectionTitle">
                    <div className="sectionTitleLeft">
                        <p><FontAwesomeIcon icon={faGraduationCap} />ბლოგი</p>
                        <h3>აქცენტები: ბლოგი, ინტერვიუ, ვებინარი</h3>
                    </div>
                    <div className="sectionTitleRight">
                        <Link href="">ყველას ნახვა <FontAwesomeIcon icon={faChevronRight} /></Link>
                    </div>
                </div>

                <Row>

                  <Col xxl={6} md={6} lg={6} xs={12}>
                    <div className="blogDetails blogBg1">
                      <Row>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsLeft">
                            <div className="blogDetailsLeftIcon1">
                              <FontAwesomeIcon icon={faNewspaper} />
                            </div>
                            <div className="blogDetailsLeftText">
                              <h4>გიორგი ხაზალია</h4>
                              <p>არღვევს თუ არა ადვოკატთა ასოციაციაში სავალდებულო წევრობა ადვოკატის მეწარმეობის თავისუფლებისა და თვითორგანიზების კონსტიტუციურ უფლებას</p>
                              <a href="#">დეტალურად <FontAwesomeIcon icon={faChevronRight} /></a>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsRight">
                            <div className="blogDetailsRightImage">
                              <Image src={Student2} alt="Gruni" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xxl={6} md={6} lg={6} xs={12}>
                    <div className="blogDetails blogBg2">
                      <Row>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsLeft">
                            <div className="blogDetailsLeftIcon2">
                              <FontAwesomeIcon icon={faNewspaper} />
                            </div>
                            <div className="blogDetailsLeftText">
                              <h4>ლუკა ბარამიძე</h4>
                              <p>ეროვნული იდენტობა/ეროვნული ეთოსი</p>
                              <a href="#">დეტალურად <FontAwesomeIcon icon={faChevronRight} /></a>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsRight">
                            <div className="blogDetailsRightImage">
                              <Image src={Student} alt="Gruni" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xxl={6} md={6} lg={6} xs={12}>
                    <div className="blogDetails blogBg3">
                      <Row>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsLeft">
                            <div className="blogDetailsLeftIcon3">
                              <FontAwesomeIcon icon={faNewspaper} />
                            </div>
                            <div className="blogDetailsLeftText">
                              <h4>ლუკა ბარამიძე</h4>
                              <p>საკონსტიტუციო სასამართლოს მიერ კონსტიტუციის განმარტების ფარგლები</p>
                              <a href="#">დეტალურად <FontAwesomeIcon icon={faChevronRight} /></a>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsRight">
                            <div className="blogDetailsRightImage">
                              <Image src={Student3} alt="Gruni" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col xxl={6} md={6} lg={6} xs={12}>
                    <div className="blogDetails blogBg4">
                      <Row>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsLeft">
                            <div className="blogDetailsLeftIcon4">
                              <FontAwesomeIcon icon={faNewspaper} />
                            </div>
                            <div className="blogDetailsLeftText">
                              <h4>ლუკა ბარამიძე</h4>
                              <p>კონსტიტუციის ინტერპრეტაციის ფორმები</p>
                              <a href="#">დეტალურად <FontAwesomeIcon icon={faChevronRight} /></a>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={6} md={6} lg={6} xs={6}>
                          <div className="blogDetailsRight">
                            <div className="blogDetailsRightImage">
                              <Image src={Student4} alt="Gruni" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                </Row>

            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Blog;
