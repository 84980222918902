//import tags
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faGraduationCap } from "@fortawesome/free-solid-svg-icons";

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

function Events() {
  return (
    <div className="events">
      <Container fluid>
        <Row>
          <Col xxl={12}>

            <div className="newsSliderBox">
                <div className="sectionTitle">
                    <div className="sectionTitleLeft">
                        <p><FontAwesomeIcon icon={faGraduationCap} />ანონსი</p>
                        <h3>მიმდინარე ანონსები</h3>
                    </div>
                    <div className="sectionTitleRight">
                        <Link href="">ყველას ნახვა <FontAwesomeIcon icon={faChevronRight} /></Link>
                    </div>
                </div>

                <Swiper
                    spaceBetween={24}
                    slidesPerView={4}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    speed={2000}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[ Autoplay, Pagination ]}
                    className="eventsSlider"
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                >

                    <SwiperSlide>
                        <div className="singleEventsBox">
                            <div className="eventsTextBox">
                                <div className="eventstTimes">
                                    <h5>18</h5>
                                    <strong>ოქტ</strong>
                                    <p>16:00</p>
                                </div>
                                <div className="eventsText">
                                    <Link href="#">
                                        <h2>ფინანსებისა და ტექნოლოგიების გადაცემის ცენტრი კლიმატის ცვლილებისთვის აცხადებს საგრა...</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleEventsBox">
                            <div className="eventsTextBox">
                                <div className="eventstTimes">
                                    <h5>18</h5>
                                    <strong>ოქტ</strong>
                                    <p>16:00</p>
                                </div>
                                <div className="eventsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleEventsBox">
                            <div className="eventsTextBox">
                                <div className="eventstTimes">
                                    <h5>18</h5>
                                    <strong>ოქტ</strong>
                                    <p>16:00</p>
                                </div>
                                <div className="eventsText">
                                    <Link href="#">
                                        <h2>ფინანსებისა და ტექნოლოგიების გადაცემის ცენტრი კლიმატის ცვლილებისთვის აცხადებს საგრა...</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleEventsBox">
                            <div className="eventsTextBox">
                                <div className="eventstTimes">
                                    <h5>18</h5>
                                    <strong>ოქტ</strong>
                                    <p>16:00</p>
                                </div>
                                <div className="eventsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleEventsBox">
                            <div className="eventsTextBox">
                                <div className="eventstTimes">
                                    <h5>18</h5>
                                    <strong>ოქტ</strong>
                                    <p>16:00</p>
                                </div>
                                <div className="eventsText">
                                    <Link href="#">
                                        <h2>ფინანსებისა და ტექნოლოგიების გადაცემის ცენტრი კლიმატის ცვლილებისთვის აცხადებს საგრა...</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleEventsBox">
                            <div className="eventsTextBox">
                                <div className="eventstTimes">
                                    <h5>18</h5>
                                    <strong>ოქტ</strong>
                                    <p>16:00</p>
                                </div>
                                <div className="eventsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                </Swiper>

            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Events;
