import React, { useState } from 'react';

//import tags
import { Container, Row, Col, Image } from "react-bootstrap";
import "swiper/swiper-bundle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

// import resources
import StudentIcon from '../../assets/image/studenticon.png'


function Students() {

    const [activeIndex, setActiveIndex] = useState(0);

    const panels = [
        { id: 0, title: "აბიტურიენტებისთვის" },
        { id: 1, title: "ბაკალავრებისთვის" },
        { id: 2, title: "მაგისტრანტებისთვის" },
        { id: 3, title: "დოქტორანტებისთვის" },
        { id: 4, title: "ბაკალავრებისთვის" },
    ];
    
     return (
    <div className="students">
      <Container>
        <Row>
          <Col xxl={12}>

            <div className="newsSliderBox">
                <div className="sectionTitle">
                    <div className="sectionTitleLeftLight">
                        <p><FontAwesomeIcon icon={faGraduationCap} />სტუდენტი</p>
                        <h3>სკოლები და პროგრამები</h3>
                    </div>
                </div>

                <div className="rowCustom">
                    
                    {panels.map((panel, index) => (
                        <div className={`colCustom ${activeIndex === index ? 'active' : ''}`}  key={panel.id} onClick={() => setActiveIndex(index)}>
                            <div className="tpPanelItem">
                                <div className="tpPanelContent">
                                    <div className='tpPanelContentImage'>
                                        <Image src={StudentIcon} alt="Gruni" />
                                    </div>
                                    <div className='tpPanelContentTitle'>
                                        <h4>{panel.title}</h4>
                                    </div>
                                    <div className='tpPanelContentBody'>
                                        <h5>უნივერსიტეტი საზღვრების გარეშე! ასე ვხედავთ საკუთრ თავს ახალი საზოგადოებრივი პროფილის ფორმირების კონტექსტში, რაც ნიშნავს იმას, რომ შევქმნით ადგილობრივ და საერთაშორისო ბაზრებზე მოთხოვნად საგანმანათლებლო პროდუქტებს</h5>
                                        <a href='#'>
                                            <div className='readMore'>დეტალურად</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Students;
