// import routes
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home";

// import NewsPage from "./pages/news/Main";
// import NewsDetailPage from "./pages/news/Detail";

import Error404 from "./Error404";

// import components
import Header from "./components/static/Header";
import Footer from "./components/static/Footer";

// import resources
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />

          {/*News*/}
          {/*<Route path="newses" element={<NewsPage />}/> */}
          {/*<Route path="news/:id/:slug" element={<NewsDetailPage />} /> */}

          {/* Route for 404 Not Found */}
          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
