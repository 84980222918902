//import tags
import { Image } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

// import required modules
import { Autoplay } from 'swiper/modules';

// import resources
import DiscoverImage from '../../assets/image/slider.png'

// import components

function Discover() {
  return (
    <div className="dicsover">

      <div className="discoverSliderBox">
          <div className="discoverSectionTitle">
              <div className="discoverSectionTitleLeft">
                <p><FontAwesomeIcon icon={faGraduationCap} /> GRUNI</p>
                <h3>საზოგადოების სამსახურში</h3>
              </div>
          </div>

        <Swiper
          spaceBetween={30}
          slidesPerView={2.5}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}
          speed={1000}
          modules={[ Autoplay ]}
          className="discoverSlider"
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >

          <SwiperSlide>
            <div className="singleDiscoverBox">
              <Image src={DiscoverImage} />
              <div className="singleDiscoverContent">
                <h2>კლინიკური მომსახურება</h2>
                <a href="#">ვრცლად</a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="singleDiscoverBox">
              <Image src={DiscoverImage} />
              <div className="singleDiscoverContent">
                <h2>Top მოვლენები</h2>
                <a href="#">ვრცლად</a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="singleDiscoverBox">
              <Image src={DiscoverImage} />
              <div className="singleDiscoverContent">
                <h2>ექსპერტიზის ცენტრი</h2>
                <a href="#">ვრცლად</a>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="singleDiscoverBox">
              <Image src={DiscoverImage} />
              <div className="singleDiscoverContent">
                <h2>სასწავლო რესურსი</h2>
                <a href="#">ვრცლად</a>
              </div>
            </div>
          </SwiperSlide>
              
        </Swiper>

      </div>

    </div>
  );
}

export default Discover;
