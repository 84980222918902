//import tags
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faChevronRight, faGraduationCap, faTags } from "@fortawesome/free-solid-svg-icons";

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

// import resources
import SliderImage from '../../assets/image/slider.jpg'
import SliderImage1 from '../../assets/image/slider.png'

// import components

function News() {
  return (
    <div className="news">
      <Container fluid>
        <Row>
          <Col xxl={12}>

            <div className="newsSliderBox">
                <div className="sectionTitle">
                    <div className="sectionTitleLeft">
                        <p><FontAwesomeIcon icon={faGraduationCap} />სიახლეები</p>
                        <h3>მიმდინარე ახალი ამბები</h3>
                    </div>
                    <div className="sectionTitleRight">
                        <Link href="">ყველას ნახვა <FontAwesomeIcon icon={faChevronRight} /></Link>
                    </div>
                </div>

                <Swiper
                    spaceBetween={24}
                    slidesPerView={4}
                    loop={true}
                    autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    speed={3000}
                    modules={[Autoplay, Pagination]}
                    className="newsSlider"
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                >

                    <SwiperSlide>
                        <div className="singleNewsBox">
                            <div className="mainNewsImage">
                                <Link href="#">
                                    <Image src={SliderImage} />
                                </Link>
                            </div>
                            <div className="newsTextBox">
                                <div className="newsTags">
                                    <span><FontAwesomeIcon icon={faCalendarDays} /> 27/09/2024</span>
                                    <span><FontAwesomeIcon icon={faTags} /> ღონისძიებები საზოგადოებისათვის</span>
                                </div>
                                <div className="newsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                    <p>ლიბრა ალიანსის პრეზიდენტი და დამფუძნებელი, გრუნის მდგრადი განვითარების ცენტრის თანადამფუძნებელი, პროფესორი მეჰდი მაჯიდი...</p>
                                    <Link href="/"><div className="newsReadMore">კითხვის გაგრძელება...</div></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleNewsBox">
                            <div className="mainNewsImage">
                                <Link href="#">
                                    <Image src={SliderImage1} />
                                </Link>
                            </div>
                            <div className="newsTextBox">
                                <div className="newsTags">
                                    <span><FontAwesomeIcon icon={faCalendarDays} /> 27/09/2024</span>
                                    <span><FontAwesomeIcon icon={faTags} /> ღონისძიებები საზოგადოებისათვის</span>
                                </div>
                                <div className="newsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                    <p>ლიბრა ალიანსის პრეზიდენტი და დამფუძნებელი, გრუნის მდგრადი განვითარების ცენტრის თანადამფუძნებელი, პროფესორი მეჰდი მაჯიდი...</p>
                                    <Link href="/"><div className="newsReadMore">კითხვის გაგრძელება...</div></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleNewsBox">
                            <div className="mainNewsImage">
                                <Link href="#">
                                    <Image src={SliderImage} />
                                </Link>
                            </div>
                            <div className="newsTextBox">
                                <div className="newsTags">
                                    <span><FontAwesomeIcon icon={faCalendarDays} /> 27/09/2024</span>
                                    <span><FontAwesomeIcon icon={faTags} /> ღონისძიებები საზოგადოებისათვის</span>
                                </div>
                                <div className="newsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                    <p>ლიბრა ალიანსის პრეზიდენტი და დამფუძნებელი, გრუნის მდგრადი განვითარების ცენტრის თანადამფუძნებელი, პროფესორი მეჰდი მაჯიდი...</p>
                                    <Link href="/"><div className="newsReadMore">კითხვის გაგრძელება...</div></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleNewsBox">
                            <div className="mainNewsImage">
                                <Link href="#">
                                    <Image src={SliderImage1} />
                                </Link>
                            </div>
                            <div className="newsTextBox">
                                <div className="newsTags">
                                    <span><FontAwesomeIcon icon={faCalendarDays} /> 27/09/2024</span>
                                    <span><FontAwesomeIcon icon={faTags} /> ღონისძიებები საზოგადოებისათვის</span>
                                </div>
                                <div className="newsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                    <p>ლიბრა ალიანსის პრეზიდენტი და დამფუძნებელი, გრუნის მდგრადი განვითარების ცენტრის თანადამფუძნებელი, პროფესორი მეჰდი მაჯიდი...</p>
                                    <Link href="/"><div className="newsReadMore">კითხვის გაგრძელება...</div></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleNewsBox">
                            <div className="mainNewsImage">
                                <Link href="#">
                                    <Image src={SliderImage} />
                                </Link>
                            </div>
                            <div className="newsTextBox">
                                <div className="newsTags">
                                    <span><FontAwesomeIcon icon={faCalendarDays} /> 27/09/2024</span>
                                    <span><FontAwesomeIcon icon={faTags} /> ღონისძიებები საზოგადოებისათვის</span>
                                </div>
                                <div className="newsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                    <p>ლიბრა ალიანსის პრეზიდენტი და დამფუძნებელი, გრუნის მდგრადი განვითარების ცენტრის თანადამფუძნებელი, პროფესორი მეჰდი მაჯიდი...</p>
                                    <Link href="/"><div className="newsReadMore">კითხვის გაგრძელება...</div></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="singleNewsBox">
                            <div className="mainNewsImage">
                                <Link href="#">
                                    <Image src={SliderImage1} />
                                </Link>
                            </div>
                            <div className="newsTextBox">
                                <div className="newsTags">
                                    <span><FontAwesomeIcon icon={faCalendarDays} /> 27/09/2024</span>
                                    <span><FontAwesomeIcon icon={faTags} /> ღონისძიებები საზოგადოებისათვის</span>
                                </div>
                                <div className="newsText">
                                    <Link href="#">
                                        <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“...</h2>
                                    </Link>
                                    <p>ლიბრა ალიანსის პრეზიდენტი და დამფუძნებელი, გრუნის მდგრადი განვითარების ცენტრის თანადამფუძნებელი, პროფესორი მეჰდი მაჯიდი...</p>
                                    <Link href="/"><div className="newsReadMore">კითხვის გაგრძელება...</div></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                </Swiper>

            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default News;
