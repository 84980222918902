import React, { useState, useEffect } from 'react';


//import tags
import { Container, Image, Row, Col } from 'react-bootstrap'

// import resources
import Logo from "../../assets/image/logos/logo2.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faChevronDown, faChevronUp, faClose, faX, faMobileAlt, faSearch, faBurger, faHamburger, faBars } from "@fortawesome/free-solid-svg-icons";
import { } from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";


function Header() {

  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSearchBoxVisible, setSearchBoxVisible] = useState(false);


  // show search box & hide body scroll
  const toggleSearchBox = () => {
    setSearchBoxVisible(!isSearchBoxVisible);
  };

  useEffect(() => {
    if (isSearchBoxVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSearchBoxVisible]);

  // sticky header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // menu
  const toggleMenu = () => setIsOpen(!isOpen);

  const handleMouseEnter = (dropdown) => {
    setActiveDropdown(dropdown);
  };

  const handleMouseLeave = () => {
    setActiveDropdown(null);
  };

  return (
    <>
      <div className='headerTop'>
        <Container fluid>
          <div className='headerTopInner'>
            <Row>
              <Col xxl={6}>
                <div className='headerTopInnerLeft'>
                  <div className='headerTopAddress'>
                    <FontAwesomeIcon icon={faAt} />
                    <a href='mailto:info@gruni.edu.ge'>info@gruni.edu.ge</a>
                  </div>
                  <div className='headerTopPhone'>
                    <FontAwesomeIcon icon={faMobileAlt} />
                    <a href='tel:+995322384406'>+995 32 2 38 44 06</a>
                  </div>
                </div>
              </Col>
              <Col xxl={6}>
                <div className='headerTopInnerRight'>
                  <div className='headerTopLanguageBox'>
                    <a href='/ka'>Ka</a>
                    <a>-</a>
                    <a href='/en'>En</a>
                  </div>
                  <div className='headerTopSocialBox'>
                    <a href="https://www.facebook.com/grunieduge" target="_blank"><div className="headerTopSingleSocial"><FontAwesomeIcon icon={faFacebookF} /></div></a>
                    <a href="https://www.youtube.com/grunieduge" target="_blank"><div className="headerTopSingleSocial"><FontAwesomeIcon icon={faYoutube} /></div></a>
                    <a href="https://twitter.com/Grunieduge" target="_blank"><div className="headerTopSingleSocial"><FontAwesomeIcon icon={faX} /></div></a>
                    <a href="https://instagram.com/grunieduge" target="_blank"><div className="headerTopSingleSocial"><FontAwesomeIcon icon={faInstagram} /></div></a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <header>
        <Container fluid>
          <div className={isSticky ? 'header sticky' : 'header'}>
            <Row>
              <Col xxl={2}>
                  <div className='mainlogo'>
                    <a href="/">
                      <Image src={Logo} alt="Gruni" />
                    </a>
                  </div>
              </Col>

              <Col xxl={10}>
                  <div className='menu'>
                    <nav className="navbar">
                      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                        <li className='mrgLft0'><a href="/">მთავარი</a></li>
                        <li 
                          className="dropdown"
                          onMouseEnter={() => handleMouseEnter('university')}
                          onMouseLeave={handleMouseLeave}
                        >
                          <a href="#!" className="dropdown-toggle">უნივერსიტეტი
                            <span className='menuArrow'>
                              <FontAwesomeIcon
                                icon={activeDropdown === 'university' ? faChevronUp : faChevronDown}
                                className={activeDropdown === 'university' ? 'rotate-up' : 'rotate-down'}
                              />
                            </span>
                          </a>
                          <ul className={`dropdown-menu ${activeDropdown === 'university' ? 'show' : ''}`}>
                            <li><a href="action">უნივერსიტეტის შესახებ</a></li>
                            <li><a href="action">ადმინისტრაცია</a></li>
                            <li><a href="action">ადმინისტრაციული აპარატი</a></li>
                            <li><a href="action">სტუდენტთა ომბუდსმენი</a></li>
                            <li><a href="action">გრიგოლ რობაქიძის შესახებ</a></li>
                          </ul>
                        </li>

                        <li 
                          className="dropdown"
                          onMouseEnter={() => handleMouseEnter('study')}
                          onMouseLeave={handleMouseLeave}
                        >
                          <a href="#!" className="dropdown-toggle">სწავლა
                            <span className='menuArrow'>
                              <FontAwesomeIcon
                                icon={activeDropdown === 'study' ? faChevronUp : faChevronDown}
                                className={activeDropdown === 'study' ? 'rotate-up' : 'rotate-down'}
                              />
                            </span>
                          </a>
                          <ul className={`dropdown-menu ${activeDropdown === 'study' ? 'show' : ''}`}>
                            <li><a href="action">ხარისხის უზუნველყოფა</a></li>
                            <li><a href="action">სასწავლო რესურსები</a></li>
                            <li><a href="action">აკადემიური კალენდარი</a></li>
                          </ul>
                        </li>

                        {/* Repeat this for each dropdown */}
                        <li 
                          className="dropdown"
                          onMouseEnter={() => handleMouseEnter('admission')}
                          onMouseLeave={handleMouseLeave}
                        >
                          <a href="#!" className="dropdown-toggle">მიღება
                            <span className='menuArrow'>
                              <FontAwesomeIcon
                                icon={activeDropdown === 'admission' ? faChevronUp : faChevronDown}
                                className={activeDropdown === 'admission' ? 'rotate-up' : 'rotate-down'}
                              />
                            </span>
                          </a>
                          <ul className={`dropdown-menu ${activeDropdown === 'admission' ? 'show' : ''}`}>
                            <li><a href="action">ფინანსური მხარდაჭერა და საგრანტო სერვისი</a></li>
                            <li><a href="action">MD Programme</a></li>
                            <li><a href="action">ბაკალავრიატი</a></li>
                            <li><a href="action">მაგისტრატურა</a></li>
                            <li><a href="action">დოქტორანტურა</a></li>
                            <li><a href="action">მობილობა</a></li>
                            <li><a href="action">სტუდენტთა ონლაინ რეგისტრაცია</a></li>
                            <li><a href="action">გასაუბრების შედეგები ქართულ ენაში</a></li>
                          </ul>
                        </li>

                        <li 
                          className="dropdown"
                          onMouseEnter={() => handleMouseEnter('research')}
                          onMouseLeave={handleMouseLeave}
                        >
                          <a href="#!" className="dropdown-toggle">კვლევა
                            <span className='menuArrow'>
                              <FontAwesomeIcon
                                icon={activeDropdown === 'research' ? faChevronUp : faChevronDown}
                                className={activeDropdown === 'research' ? 'rotate-up' : 'rotate-down'}
                              />
                            </span>
                          </a>
                          <ul className={`dropdown-menu ${activeDropdown === 'research' ? 'show' : ''}`}>
                            <li><a href="action">სამეცნიერო კვლევების მართვის ცენტრი</a></li>
                            <li><a href="action">კვლევების და ინიციატივების დაფინანსება</a></li>
                            <li><a href="action">რეკომენდირებული გამოცემები</a></li>
                            <li><a href="action">პუბლიკაციები</a></li>
                            <li><a href="action">განხორციელებული პროექტები</a></li>
                            <li><a href="action">განხორციელებული კვლევები</a></li>
                            <li><a href="action">სადოქტორო დისერტაციები</a></li>
                          </ul>
                        </li>

                        <li 
                          className="dropdown"
                          onMouseEnter={() => handleMouseEnter('international')}
                          onMouseLeave={handleMouseLeave}
                        >
                          <a href="#!" className="dropdown-toggle">საერთაშორისო
                            <span className='menuArrow'>
                              <FontAwesomeIcon
                                icon={activeDropdown === 'international' ? faChevronUp : faChevronDown}
                                className={activeDropdown === 'international' ? 'rotate-up' : 'rotate-down'}
                              />
                            </span>
                          </a>
                          <ul className={`dropdown-menu ${activeDropdown === 'international' ? 'show' : ''}`}>
                            <li><a href="action">საერთაშორისო ინტეგრაცია</a></li>
                          </ul>
                        </li>

                        <li>
                          <div className='searchIcon' onClick={toggleSearchBox}>
                              <FontAwesomeIcon icon={faSearch} />
                          </div>
                        </li>

                      </ul>

                      <button className="menu-toggle mobileMenu" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBars} />
                      </button>
                    </nav>
                  </div>
              </Col>
            </Row>
          </div>
            
        </Container>
      </header>

      {isSearchBoxVisible && (
        <div className='searchBox'>
          <div className='searchBoxInner'>
            <div className='searchBoxInnerHeader'>
              <h3>ძებნა</h3>
              <div className='closeSearchBox' onClick={toggleSearchBox}>
                <FontAwesomeIcon icon={faClose} />
              </div>
            </div>
            <div className='searchBoxInnerBody'>
              <form>
                <div className='searchInputBox'>
                  <input type='text' name='search' className='searchInput' placeholder='ჩაწერეთ საძიებო სიტყვა...' />
                  <button type='button' className='searchButton'>ძიება</button>
                </div>
              </form>
              <div className='searchInputResults'>
                <div className='searchInputSingleResults'>
                  <h4>სიახლე <span>6</span></h4>
                  <ul>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                  </ul>
                </div>
                <div className='searchInputSingleResults'>
                  <h4>ანონსი <span>3</span></h4>
                  <ul>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                  </ul>
                </div>
                <div className='searchInputSingleResults'>
                  <h4>ვიდეოები <span>1</span></h4>
                  <ul>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                  </ul>
                </div>
                <div className='searchInputSingleResults'>
                  <h4>აკადემიური პერსონალი <span>5</span></h4>
                  <ul>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                  </ul>
                </div>
                <div className='searchInputSingleResults'>
                  <h4>მენიუს პუნქტები <span>2</span></h4>
                  <ul>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                    <a href=''>
                      <li>საჯარო ლექცია თემაზე: „საქართველო-გეოპოლიტიკური...</li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )

}

export default Header;