import React, { useState, useEffect, useRef } from 'react';

//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { } from '@fortawesome/free-solid-svg-icons';
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';

// import required modules
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';

// import resources
import SliderImage from '../../assets/image/slider.jpg'
import SliderImage2 from '../../assets/image/slider1.jpg'

function Slider() {

    const [isVideoBoxVisible, setVideoBoxVisible] = useState(false);

     // show video box & hide body scroll
    const toggleVideoBox = () => {
        setVideoBoxVisible(!isVideoBoxVisible);
    };

    useEffect(() => {
        if (isVideoBoxVisible) {
        document.body.style.overflow = 'hidden';
        } else {
        document.body.style.overflow = 'auto';
        }

        return () => {
        document.body.style.overflow = 'auto';
        };
    }, [isVideoBoxVisible]);

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    return (
        <>
            <div className='slider'>
                <Swiper
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                effect={'fade'}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                speed={1000}
                modules={[ Autoplay, EffectFade, Pagination, Navigation ]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className='swiperSlider'
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                >
                    <SwiperSlide>
                        <div className='sldierImage'>
                            <Image src={SliderImage2} />
                        </div>
                        <div className='sLiderTextBox'>
                            <Container>
                                <Row>
                                    <Col xxl={8}>
                                        <div className='sldierText'>
                                            <h2>გრუნი გაეროს “განათლების პასუხისმგებლიანი მენეჯმეტის პრინციპების“ ხელმომწერი ინსტიტუტების წევრია</h2>
                                            <p>ლიბრა ალიანსის პრეზიდენტი და დამფუძნებელი, გრუნის მდგრადი განვითარების ცენტრის თანადამფუძნებელი, პროფესორი მეჰდი მაჯიდი, მონაწილეობდა ჩიკაგოში გამართული...</p>
                                            <div className='sliderButtons'>
                                                <Link href="/">
                                                    <div className='readMore'>ვრცლად</div>
                                                </Link>
                                                <div onClick={toggleVideoBox}>
                                                    <div className='viewVideo'><FontAwesomeIcon icon={faPlayCircle} /> ვიდეოს ნახვა</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className='sldierImage'>
                            <Image src={SliderImage} />
                        </div>
                        <div className='sLiderTextBox'>
                            <Container>
                                <Row>
                                    <Col xxl={8}>
                                        <div className='sldierText'>
                                            <h2>ტეხას ა&მ უნივერსიტეტის პროგრამების მონაწილეთა დაჯილდოების საზეიმო ცერემონია გრუნიში</h2>
                                            <p>2024 წლის 16 თებერვალს გრიგოლ რობაქიძის სახელობის უნივერსიტეტში საზეიმოდ დაიხურა ტეხას ა&მ უნივერსიტეტის ინგლისური ენის სპეციალისტთა გადამზადების- „TESOL“ პროგრამის...</p>
                                            <div className='sliderButtons'>
                                                <Link href="/">
                                                    <div className='readMore'>ვრცლად</div>
                                                </Link>
                                                <div onClick={toggleVideoBox}>
                                                    <div className='viewVideo'><FontAwesomeIcon icon={faPlayCircle} /> ვიდეოს ნახვა</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </SwiperSlide>

                    <div className="autoplay-progress" slot="container-end">
                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                            <circle cx="24" cy="24" r="20"></circle>
                        </svg>
                        <span ref={progressContent}></span>
                    </div>

                </Swiper>
            </div>
            
            {isVideoBoxVisible && (
                <div className='videoBox' onClick={toggleVideoBox}>
                    <div className='videoBoxInner'>
                        <div className='closeVideoBox' onClick={toggleVideoBox}>
                            <FontAwesomeIcon icon={faClose} />
                        </div>
                        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/aS7lmAnMdDo?si=xh-gQWgZKdRb3PSG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            )}
        </>
    )

}

export default Slider;
