import { useState, useEffect } from "react";

//import tags
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";


// import resources
import FooterLogo from "../../assets/image/logos/footerLogo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardUser, faChevronRight, faChevronUp, faEnvelope, faLaptopFile, faLocation, faPhone, faUsersRectangle, faUsersViewfinder, faX } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { } from "@fortawesome/free-regular-svg-icons";

function Footer() {

  const [showButton, setShowButton] = useState(false);

  // Show button when the user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer">
      <div className="footerTop">
        <Container>

          <div className="footerSubscribeBox">
            <Row>
              <Col xxl={7}>
                <div className="newsletterText">
                  <h4>სიახლეების გამოწერა</h4>
                  <p>გამოიწერეთ სიახლეები რათა არ გამოგრჩეთ მნიშვნელოვანი ამბები!</p>
                </div>
              </Col>
              <Col xxl={5}>
                <form>
                  <div className="subscribeInputBox">
                    <input type="email" name="newsletter" placeholder="თქვენი ელ.ფოსტა" />
                    <button type="submit" name="subscribe" className="subscribeButton">გამოწერა</button>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
          <Row>
            <Col xxl={3}>
              <div className="footerWidgetBox">
                <h4 className="footerWidgetBoxTitle">მიზნობრივი ბმულები</h4>
                <ul>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">ინსტიტუტები, ცენტრები</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">აბიტურიენტთათვის</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">მობილობა სტუდენტებისათვის</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">კურსდამთავრებულებისთვის</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">თანამშრომლებისთვის</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">დამსაქმებლებისთვის</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">კარიერული სერვისი</Link></li>
                </ul>
              </div>
            </Col>
            <Col xxl={3}>
              <div className="footerWidgetBox">
                <h4 className="footerWidgetBoxTitle">სამართლებრივი აქტები</h4>
                <ul>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">კანონმდებლობა</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">უნივერსიტეტის წესდება</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">დებულებები</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">ინსტრუქციები</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">ბრძანებები</Link></li>
                </ul>
              </div>
            </Col>
            <Col xxl={3}>
              <div className="footerWidgetBox">
                <h4 className="footerWidgetBoxTitle">სწრაფი ბმულები</h4>
                <ul>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">ბიბლიოთეკა</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">გამოცემები</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">პროგრამების კატალოგი</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">აკადემიური მაცნე</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">კერძო უმაღლესი სასწავლებლების ასოციაცია</Link></li>
                  <li><FontAwesomeIcon icon={faChevronRight} /><Link href="/">კვლევებისა და ინიციატივების დაფინანსება</Link></li>
                </ul>
              </div>
            </Col>
            <Col xxl={3}>
              <div className="footerWidgetBox">
                <h4 className="footerWidgetBoxTitle">საკონტაქტო ინფორმაცია</h4>
                <ul>
                  <li className="footerContact"><FontAwesomeIcon className="footerContactIcon" icon={faLocation} /><Link href="/">თბილისი, ირინა ენუქიძის #3</Link></li>
                  <li className="footerContact"><FontAwesomeIcon className="footerContactIcon" icon={faPhone} /><Link href="tel:+995322384406">+995 32 2384406</Link></li>
                  <li className="footerContact"><FontAwesomeIcon className="footerContactIcon" icon={faPhone} /><Link href="tel:+995322382706">+995 32 2382706</Link></li>
                  <li className="footerContact"><FontAwesomeIcon className="footerContactIcon" icon={faLocation} /><Link href="/">ბათუმი, თამარ მეფის ქუჩა N60, 6000</Link></li>
                  <li className="footerContact"><FontAwesomeIcon className="footerContactIcon" icon={faPhone} /><Link href="tel:+9950422245133">+995 042 2245133</Link></li>
                  <li className="footerContact"><FontAwesomeIcon className="footerContactIcon" icon={faEnvelope} /><Link href="mailto:info@gruni.edu.ge">info@gruni.edu.ge</Link></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footerBottom">
        <Container>
          <Row>
            <Col xxl={12}>
              <Row>
                <Col xxl={4}>
                  <div className="footerBottomSocial">
                    <div className="soacialMedia">
                      <a href="https://www.facebook.com/grunieduge" target="_blank"><div className="singleSocial"><FontAwesomeIcon icon={faFacebookF} /></div></a>
                      <a href="https://www.youtube.com/grunieduge" target="_blank"><div className="singleSocial"><FontAwesomeIcon icon={faYoutube} /></div></a>
                      <a href="https://twitter.com/Grunieduge" target="_blank"><div className="singleSocial"><FontAwesomeIcon icon={faX} /></div></a>
                      <a href="https://instagram.com/grunieduge" target="_blank"><div className="singleSocial"><FontAwesomeIcon icon={faInstagram} /></div></a>
                    </div>
                  </div>
                </Col>
                <Col xxl={4}>
                  <div className="footerMiddleBox">
                    <div className="footerLogo">
                      <a href="/">
                        <Image src={FooterLogo} alt="Logo" />
                      </a>
                    </div>
                    <div className="footerBottomRights">
                      <p>
                        &copy; 2024 გრიგოლ რობაქიძის სახელობის უნივერსიტეტი.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xxl={4}>
                  <div className="footerBottomLinks">
                    <div className="linksMedia">
                      <a href="#" title="Docflow" target="_blank"><div className="singleLinks"><FontAwesomeIcon icon={faLaptopFile} /></div></a>
                      <a href="#" title="Nexus Students" target="_blank"><div className="singleLinks"><FontAwesomeIcon icon={faUsersViewfinder} /></div></a>
                      <a href="#" title="Nexus Professors" target="_blank"><div className="singleLinks"><FontAwesomeIcon icon={faUsersRectangle} /></div></a>
                      <a href="#" title="GruniCard" target="_blank"><div className="singleLinks"><FontAwesomeIcon icon={faChalkboardUser} /></div></a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      {showButton && (
        <button
          onClick={scrollToTop}
          className="scrollToTop"
        >
          <FontAwesomeIcon className="bounceArrow" icon={faChevronUp} />
        </button>
      )}
    </footer>
  );
}

export default Footer;
