// import components
import Slider from '../../src/components/dynamic/slider'
import News from '../../src/components/dynamic/news'
import Events from '../../src/components/dynamic/events'
import Students from '../../src/components/dynamic/students'
import Blog from '../../src/components/dynamic/blog'
import Discover from '../../src/components/dynamic/discover'

function Home() {

    return (
        <div className='main'>
            <Slider/>
            <News/>
            <Events/>
            <Students/>
            <Blog/>
            <Discover/>
        </div>
    )

}

export default Home;
